import { useState } from "react";
import Image from "next/image";
import { useDeletePropertyMutation } from "../../lib/api/properties/hooks";
import AddPalaceIdModal from "../../components/modals/palace/AddPalaceIdModal";
import { Box, Flex } from "@chakra-ui/layout";
import { Link, Menu, MenuButton, MenuItem, MenuList, Tooltip } from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";
import NextLink from "next/link";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";

export default function PropertyListMenu({
  propertyId,
  openPalaceModalPropertyId,
  propertyPalaceId,
}: {
  propertyId: string;
  propertyPalaceId?: string;
  openPalaceModalPropertyId: (propId: string) => void;
}) {
  const [openPalaceModal, setOpenPalaceModal] = useState(false);
  const [openDeletePropertyModal, setOpenDeletePropertyModal] = useState(false);
  const deletePropertyMutation = useDeletePropertyMutation();

  const handleDeleteProperty = () => {
    setOpenDeletePropertyModal(false);
    deletePropertyMutation.mutate({
      input: {
        id: propertyId,
      },
    });
  };

  return (
    <Box>
      <Flex justifyContent="end">
        {Boolean(propertyPalaceId) && (
          <Tooltip label={propertyPalaceId}>
            <Box mr={5}>
              <Image
                alt={propertyPalaceId ?? "palace"}
                style={{ borderRadius: 10 }}
                src="/assets/palaceimage.png"
                width={25}
                height={25}
              />
            </Box>
          </Tooltip>
        )}
        <Box display="flex">
          <Menu>
            <MenuButton>
              <BsThreeDots size={20} />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setOpenPalaceModal(true);
                }}
              >
                {propertyPalaceId ? "Change Palace ID" : "Add Palace ID"}
              </MenuItem>
              <NextLink href={`/property/${propertyId}`} passHref legacyBehavior>
                <MenuItem>
                  <Link>View property</Link>
                </MenuItem>
              </NextLink>
              <NextLink href={`/property/${propertyId}/edit`} passHref legacyBehavior>
                <MenuItem>
                  <Link>Edit property</Link>
                </MenuItem>
              </NextLink>
              <MenuItem
                onClick={() => {
                  setOpenDeletePropertyModal(true);
                }}
                color="red"
              >
                Delete property
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
      {Boolean(openPalaceModal) && (
        <AddPalaceIdModal propertyId={propertyId} onClose={() => setOpenPalaceModal(false)} />
      )}
      {Boolean(openDeletePropertyModal) && (
        <ConfirmationModal
          confirmButtonColorScheme="red"
          isOpen={true}
          title="Are you sure you want to delete this property?"
          onClose={() => setOpenDeletePropertyModal(false)}
          onConfirm={handleDeleteProperty}
          confirmationText="Delete"
          isLoading={deletePropertyMutation.isLoading}
        />
      )}
    </Box>
  );
}
