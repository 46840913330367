import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";

import { Input } from "@chakra-ui/input";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { FormErrorMessage } from "@chakra-ui/react";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { api } from "../../../lib/api/api";
import { QueryKeys, useGetProperty } from "../../../lib/api/properties/hooks";

export default function AddPalaceIdModal({
  propertyId,
  onUpdatePalaceId,
  onClose,
}: {
  propertyId: string;
  onUpdatePalaceId?: (propertyId: string, palaceId: string) => void;
  onClose: () => void;
}) {
  const [palaceIdText, setPalaceIdText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const queryClient = useQueryClient();

  const { property } = useGetProperty(propertyId!);

  const isOpen = Boolean(propertyId);

  const savePalaceId = async () => {
    if (!palaceIdText) {
      setErrorMessage("Palace ID not set");
      return;
    }

    setErrorMessage("");

    try {
      await api.post(`/v2/installation/${propertyId}/palacepropertyid`, {
        palacePropertyId: palaceIdText,
      });
      await queryClient.invalidateQueries([QueryKeys.PROPERTY, { orgPropertyInfo: true }]);
      onClose();
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
        return;
      }

      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={2}>Add Palace ID </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {property ? property.address1 : ""}
            <FormControl mt={4}>
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
              <Input
                value={palaceIdText}
                onChange={(e) => {
                  setPalaceIdText(e.target.value);
                }}
                placeholder="E.g RBPXXXX"
              ></Input>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={2}
              colorScheme="blue"
              onClick={() => {
                savePalaceId();
              }}
            >
              Add Palace ID
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
