import {
  useGetCircuit,
  useGetCircuitNode,
  useGetOutside,
  useGetProperty,
  useGetPropertyDetail,
  useGetRoom,
} from "../api/properties/hooks";
import { useGetPulseMeter } from "../api/pulseMeter/hooks";
import { DeviceType } from "../api/types/GenericDevice";

export type AreaType = "room" | "outside" | "circuit";

export const useAreaPageData = (areaType: AreaType, areaId: string) => {
  if (areaType !== "room" && areaType !== "outside" && areaType !== "circuit") {
    throw new Error(`Invalid area type: ${areaType}`);
  }

  const { room, isLoading: roomLoading, error: roomError } = useGetRoom(areaId, {
    retry: false,
    enabled: areaType === "room",
  });

  const { outside, isLoading: outsideLoading, error: outsideError } = useGetOutside(areaId, {
    retry: false,
    enabled: areaType === "outside",
  });

  const { circuit, isLoading: circuitLoading, error: circuitError } = useGetCircuit(areaId, {
    retry: false,
    enabled: areaType === "circuit",
  });

  const { circuitNode } = useGetCircuitNode(circuit?.circuitNodeId, {
    retry: false,
    enabled: areaType === "circuit" && circuit?.circuitNodeId !== undefined,
  });

  const area = room || outside || circuit;
  const areaLoading = roomLoading || outsideLoading || circuitLoading;

  const areaError =
    roomError &&
    outsideError &&
    circuitError &&
    ([roomError, outsideError, circuitError].find(
      (e) => e?.message && e.message.toLowerCase().includes("not found")
    ) ||
      roomError);

  const {
    propertyInfo,
    isLoading: propertyInfoLoading,
    error: propertyInfoError,
  } = useGetPropertyDetail(area?.propertyId || "", { enabled: area !== undefined });

  const { property, isLoading: propertyLoading, error: propertyError } = useGetProperty(
    area?.propertyId || "",
    { enabled: area !== undefined }
  );

  const roomInfo = areaType === "room" ? propertyInfo?.rooms.find((r) => r.id === areaId) : undefined;
  const roomLocations = roomInfo?.roomLocations;
  const roomLocationsWithDevice = roomLocations?.filter((rl) => rl.device);

  const outsideInfo =
    areaType === "outside" ? propertyInfo?.outsides.find((o) => o.id === areaId) : undefined;
  const circuitInfo =
    areaType === "circuit" ? propertyInfo?.circuits.find((c) => c.id === areaId) : undefined;

  const { pulseMeter, isLoading: pulseMeterLoading } = useGetPulseMeter(outsideInfo?.device?.id, {
    enabled: outsideInfo?.device?.type === DeviceType.ADEUNIS_PULSE,
  });

  const loading = propertyInfoLoading || propertyLoading || areaLoading || pulseMeterLoading;
  const error = areaError || propertyInfoError || propertyError;
  const hasDevices =
    (roomLocationsWithDevice !== undefined && roomLocationsWithDevice.length > 0) ||
    outsideInfo?.device !== undefined ||
    circuitInfo?.device !== undefined;

  const comfortScore =
    roomInfo && roomLocations && roomLocations.length > 0
      ? roomLocations[0].insights?.comfortScore
      : undefined;

  return {
    loading,
    error,
    hasDevices,
    comfortScore,
    area,
    room,
    circuit,
    circuitNode,
    outside,
    roomInfo,
    outsideInfo,
    circuitInfo,
    roomLocations,
    property,
    propertyInfo,
    areaType: (room && "room") || (circuit && "circuit") || ("outside" as AreaType),
    pulseMeter,
  };
};
