import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../api";
import { PulseMeter } from "../types/PulseMeter";

enum QueryKeys {
  PulseMeter = "pulse-meter",
}
export const useGetPulseMeter = (
  deviceId: string | undefined,
  options?: UseQueryOptions<PulseMeter, Error>
) => {
  const { data: data, ...rest } = useQuery<PulseMeter, Error>(
    [QueryKeys.PulseMeter, deviceId],
    () => api.get(`/v2/pulsemeter/${deviceId}`),
    {
      staleTime: Infinity,
      ...options,
      enabled: Boolean(deviceId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return {
    pulseMeter: data,
    ...rest,
  };
};
