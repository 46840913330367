import { Box, BoxProps, Flex, Link, Text } from "@chakra-ui/react";
import NextLink from "next/link";
import { UserPinnedEntity } from "../../lib/api/types/UserPinnedEntity";
import { AreaType, useAreaPageData } from "../../lib/hooks/useAreaPageData";
import { RoomLocationReading } from "../areas/room-location-reading/RoomLocationReading";
import { Loader } from "../loading/Loader";
import { ChevronRightIcon } from "@chakra-ui/icons";

interface PinnedAreaProps extends BoxProps {
  pinnedEntity: UserPinnedEntity;
}

const PinnedArea = ({ pinnedEntity, ...others }: PinnedAreaProps) => {
  const { loading, error, area, property, propertyInfo, roomLocations } = useAreaPageData(
    pinnedEntity.entityType.toLowerCase() as AreaType,
    pinnedEntity.entityId as string
  );

  if (loading) {
    return <Loader mt={10} />;
  }
  if (error || !area || !property || !propertyInfo) {
    return null;
  }

  return (
    <Box
      flexDir="column"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="lg"
      width="100%"
      px={5}
      py={4}
      {...others}
    >
      <Flex width="100%">
        <Box overflow="hidden" width="100%">
          <NextLink
            href={`/area/${pinnedEntity.entityType.toLowerCase()}/${area.id}`}
            passHref
            legacyBehavior
          >
            <Link>
              <Flex width="100%" justifyContent="space-between">
                <Text fontWeight="bold">{area.name}</Text>
                <ChevronRightIcon height={6} width={6} fontSize="lg" mt={-1} />
              </Flex>
            </Link>
          </NextLink>
        </Box>
      </Flex>

      {!loading && !error && !!roomLocations?.length && (
        <Box bg="white" borderRadius="md">
          {roomLocations.map((l) => (
            <Box key={l.id}>
              <RoomLocationReading
                showCovidRiskLevel={true}
                roomLocation={l}
                device={l.device}
                locationType="ROOMLOCATION"
                propertyId={area.propertyId}
                showRoomName={roomLocations.length > 1 || area.name !== l.name}
                disableInput={true}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PinnedArea;
