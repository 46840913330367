import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import { useGetAllPropertyTags, useGetStreetViewImage } from "../../lib/api/properties/hooks";
import { PropertyInfoFlattened } from "../../lib/api/types/PropertyInfo";
import { PropertyIcon } from "../create-property/property-icons/PropertyIcon";
import NextLink from "next/link";
import formatAddress from "../../lib/utils/formatAddress";
import { PropertyTagList } from "../property/PropertyTagList";
import { UserPinnedEntity } from "../../lib/api/types/UserPinnedEntity";
import PinnedArea from "./PinnedArea";
import { DeviceCounts } from "../device/DeviceCounts";

interface PinnedPropertyProps {
  propertyInfo: PropertyInfoFlattened;
  pinnedEntities: UserPinnedEntity[];
}

const PinnedProperty = ({ propertyInfo, pinnedEntities }: PinnedPropertyProps) => {
  const { imageUrl } = useGetStreetViewImage(propertyInfo.id);

  return (
    <Flex flexDir="column">
      <Flex>
        {imageUrl !== undefined && (
          <Box width={45} height={45} borderRadius="lg" overflow="hidden">
            <Image alt="property image" src={imageUrl.imageUrl} />
          </Box>
        )}
        {imageUrl === undefined && <PropertyIcon size={45} type={propertyInfo.type} />}

        <Box ml={2} width="100%">
          <Flex justifyContent="space-between" width="100%">
            <NextLink href={`/property/${propertyInfo.id}`} passHref legacyBehavior>
              <Link>{formatAddress(propertyInfo)}</Link>
            </NextLink>
            <DeviceCounts deviceCounts={propertyInfo.deviceCounts} size="sm" />
          </Flex>

          <Flex>
            {!!propertyInfo.customPropertyId && (
              <Flex mt={0.5}>
                <Text whiteSpace="nowrap">{propertyInfo.customPropertyId}</Text>
                <Box mt={2} mx={2} background="gray.200" height={1.5} width={1.5} borderRadius={10} />
              </Flex>
            )}
            <PropertyTagList tags={propertyInfo.propertyTags} size="xs" />
          </Flex>
        </Box>
      </Flex>

      <Box display="flex" flexDir="column">
        {pinnedEntities.map((entity) => (
          <PinnedArea
            key={entity.entityId}
            pinnedEntity={entity}
            sx={{
              "&:not(:nth-child(1))": {
                marginTop: 2,
              },
            }}
          />
        ))}
      </Box>
    </Flex>
  );
};

export default PinnedProperty;
