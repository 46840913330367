import { NextPage } from "next";
import React, { useEffect, useMemo, useState } from "react";

import Link from "next/link";
import AddPalaceIdModal from "../../components/modals/palace/AddPalaceIdModal";
import { useGetOrganisationPropertyInfo } from "../../lib/api/properties/hooks";
import { PropertyInfoFlattened } from "../../lib/api/types/PropertyInfo";
import getPropertyListColumns from "../propertyList/propertyListColumns";
import { Alert, AlertIcon, Box, Button, Heading, HStack, Image, Stack } from "@chakra-ui/react";
import { DataTableContainer } from "../../components/table/DataTableContainer";
import Head from "next/head";
import { useHasPermission } from "../../lib/hooks/useHasPermission";
import { GenerateReportButton } from "../../components/reporting/GenerateReportButton";
import { useGetPinnedEntities } from "../../lib/api/pinned-entities/hooks";
import { UserPinnedEntityType } from "../../lib/api/types/UserPinnedEntity";
import PinnedProperty from "../../components/property-list/PinnedProperty";
import { useAuth } from "../../lib/auth/useAuth";
import { useRouter } from "next/router";
import { NewUserModal } from "../../components/new-user/NewUserModal";
import { useGetUser } from "../../lib/api/users/hooks";
import { User } from "../../lib/api/types/User";
import moment from "moment";
import { NewUserMobileModal } from "../../components/new-user/NewUserMobileModal";

const NewUserContainer = ({ onClose }: { onClose: () => void }) => {
  const isMobile = window.innerWidth < 768;
  return (
    <div>{isMobile ? <NewUserMobileModal onClose={onClose} /> : <NewUserModal onClose={onClose} />}</div>
  );
};

const PropertiesPage: NextPage = () => {
  const router = useRouter();
  const [palaceModalPropertyId, setPalaceModalPropertyId] = useState<string | null>(null);
  const { user } = useAuth();

  const { data = [], isLoading: propertiesLoading } = useGetOrganisationPropertyInfo();
  const { pinnedEntities, isLoading: pinsLoading } = useGetPinnedEntities(UserPinnedEntityType.Room);

  const hasCreatePropertyPermission = useHasPermission("CREATE_PROPERTY");
  const hideDeviceCounts = data.length > 100;

  const columns = getPropertyListColumns({
    openPalaceModalPropertyId: (propertyId: string) => {
      setPalaceModalPropertyId(propertyId);
    },
    hideDeviceCounts,
  });

  const defaultSort = hideDeviceCounts
    ? { accessor: "address1", desc: false }
    : { accessor: "totalDeviceCount", desc: true };

  const propertiesMemo: PropertyInfoFlattened[] = useMemo(() => {
    return data.map((d) => {
      const totalDeviceCount = !d.deviceCounts
        ? 0
        : [
            d.deviceCounts.airwits,
            d.deviceCounts.airwits_iaq,
            d.deviceCounts.airwits_co2,
            d.deviceCounts.airwits_co2_plus,
            d.deviceCounts.airwits_pm,
            d.deviceCounts.enviroq,
            d.deviceCounts.thermalq,
            d.deviceCounts.hotdrop,
            d.deviceCounts.flexsense,
            d.deviceCounts.voltdrop,
            d.deviceCounts.room_radar,
            d.deviceCounts.adeunis_pulse,
            d.deviceCounts.adeunis_pulse_water,
          ]
            .filter((d) => d)
            .reduce((prev, cur) => {
              return prev + cur;
            }, 0);

      return {
        ...d,
        totalDeviceCount,
      };
    });
  }, [data]);

  const pinnedProperties = useMemo(() => {
    return propertiesMemo.filter((p) => pinnedEntities?.find((e) => e.propertyId === p.id));
  }, [propertiesMemo, pinnedEntities]);

  const isLoading = propertiesLoading || pinsLoading;

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const { user: authUser } = useGetUser(user?.id as string);

  useEffect(() => {
    if (authUser) {
      // Get local storage value
      const hideWelcomeModal = localStorage.getItem("hideWelcomeModal" + authUser.id);
      if (hideWelcomeModal) {
        return;
      }
      const momentDate1 = moment(authUser.createdAt);
      const momentDate2 = moment();

      // Calculate the difference in days between the two dates
      const diffInDays = momentDate2.diff(momentDate1, "days");

      if (diffInDays < 7) {
        setShowWelcomeModal(true);
      }
    }
  }, [authUser]);

  const handleCloseWelcomeBanner = () => {
    if (authUser) {
      localStorage.setItem("hideWelcomeModal" + authUser.id, "true");
    }
    setShowWelcomeModal(false);
  };

  return (
    <>
      <>
        <Head>
          <title>Tether | Properties</title>
        </Head>

        {showWelcomeModal && <NewUserContainer onClose={handleCloseWelcomeBanner} />}

        <div style={{ margin: 24 }}>
          <DataTableContainer
            pageTitle={"Properties"}
            fontSize="xl"
            columns={columns}
            data={propertiesMemo}
            showFilter={true}
            defaultSort={defaultSort}
            hiddenColumns={["address2", "addressCity", "addressPostcode", "propertyTags"]}
            isLoading={isLoading}
            actions={
              <HStack>
                <GenerateReportButton
                  showHideAddressesOption={true}
                  showPropertyTags={true}
                  buttonTitle="Generate Monthly Report"
                  reportType="MANAGEMENT"
                  entityId={user?.currentOrganisationId}
                  customReportConfirm={(
                    startDate: string,
                    endDate: string,
                    tags?: string[],
                    hideAddresses?: boolean
                  ) => {
                    router.push(
                      `/reports/management?startDate=${startDate}&endDate=${endDate}&tags=${tags}&hideAddresses=${hideAddresses}`
                    );
                  }}
                />
                {Boolean(hasCreatePropertyPermission) && (
                  <Link href="/create-property" passHref legacyBehavior>
                    <Button colorScheme={"blue"}>Create Property</Button>
                  </Link>
                )}
              </HStack>
            }
          >
            <>
              {!isLoading && pinnedProperties && pinnedProperties.length > 0 && (
                <Box mb={10} bg={"white"} p={5} borderRadius="lg">
                  <Heading size="md" mb={5}>
                    Pinned Properties
                  </Heading>
                  {pinnedProperties.map((propertyInfo) => (
                    <PinnedProperty
                      key={propertyInfo.id}
                      propertyInfo={propertyInfo}
                      pinnedEntities={pinnedEntities?.filter((e) => e.propertyId === propertyInfo.id) || []}
                    />
                  ))}
                </Box>
              )}
            </>
          </DataTableContainer>
        </div>
        {Boolean(!isLoading && propertiesMemo.length === 0) && (
          <Alert colorScheme={"blue"} justifyContent="center">
            <Stack>
              <HStack mr={2}>
                <AlertIcon />
                <Box>You do not have any properties</Box>
              </HStack>
              <Box textAlign={"center"}>
                {Boolean(hasCreatePropertyPermission) && (
                  <Link href="/create-property" passHref legacyBehavior>
                    <Button colorScheme={"blue"}>Create Property</Button>
                  </Link>
                )}
              </Box>
            </Stack>
          </Alert>
        )}

        {Boolean(palaceModalPropertyId) && (
          <AddPalaceIdModal
            onClose={() => {
              setPalaceModalPropertyId(null);
            }}
            propertyId={palaceModalPropertyId!}
          />
        )}
      </>
    </>
  );
};

/*
<DataTable dt={dt} />
<SearchFilter
                preGlobalFilteredRows={data}
                globalFiltertext={dt.globalFilter}
                setGlobalFilterText={dt.setGlobalFilter}
                filterPlaceholderText={"Search Properties..."}
              />*/

export default PropertiesPage;
