import React, { useState } from "react";
import { Column, Row } from "react-table";
import { Box, Center, Flex, Heading, HStack, Text } from "@chakra-ui/layout";
import Image from "next/image";
import NextLink from "next/link";
import { Link, Menu, MenuButton, MenuItem, MenuList, Tag, Tooltip } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { FaCheckCircle } from "react-icons/fa";
import { PIDeviceTypes, PropertyInfoFlattened } from "../../lib/api/types/PropertyInfo";
import { IoHelpCircleSharp } from "react-icons/io5";
import HouseIcon from "../../components/icons/HouseIcon";
import formatAddress from "../../lib/utils/formatAddress";
import _ from "lodash";
import PropertyListMenu from "./propertyListMenu";
import { PropertyIcon } from "../../components/create-property/property-icons/PropertyIcon";
import TetherTickIcon from "../../components/icons/TetherTickIcon";
import { BiHelpCircle } from "react-icons/bi";
import { PropertyTagList } from "../../components/property/PropertyTagList";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { DeviceCounts } from "../../components/device/DeviceCounts";

interface PropertyListColumnProps {
  openPalaceModalPropertyId: (propertyId: string) => void;
  hideDeviceCounts: boolean;
}

export default function getPropertyListColumns({
  openPalaceModalPropertyId,
  hideDeviceCounts,
}: PropertyListColumnProps) {
  const columnHelper = createColumnHelper<PropertyInfoFlattened>();

  const columns: ColumnDef<PropertyInfoFlattened, any>[] = [
    columnHelper.accessor("type", {
      header: () => <Text fontSize={12}>TYPE</Text>,
      cell: ({ row }) => {
        return (
          <Tooltip label={_.startCase(row.original.type)}>
            <PropertyIcon size={34} type={row.original.type} />
          </Tooltip>
        );
      },
    }),

    columnHelper.accessor("address1", {
      header: () => <Text fontSize={12}>ADDRESS</Text>,
      cell: ({ row }) => {
        return (
          <Box overflow="hidden">
            <NextLink href={`/property/${row.original.id}`} passHref legacyBehavior>
              <Link>{formatAddress(row.original)}</Link>
            </NextLink>
            {Boolean(row.original.propertyTags?.length) && (
              <Flex height={7} pt={0.5}>
                <PropertyTagList tags={row.original.propertyTags} size="xs" overflow="grouped" />
              </Flex>
            )}
          </Box>
        );
      },
    }),

    columnHelper.accessor("customPropertyId", {
      header: () => <Text fontSize={12}>Custom ID</Text>,
      cell: ({ row }) => {
        if (!row.original.customPropertyId) {
          return null;
        }
        return (
          <NextLink href={`/property/${row.original.id}`} passHref legacyBehavior>
            <Link>{row.original.customPropertyId ? row.original.customPropertyId : "N/A"}</Link>
          </NextLink>
        );
      },
    }),
    columnHelper.accessor("totalDeviceCount", {
      enableHiding: hideDeviceCounts,
      header: () =>
        hideDeviceCounts ? null : (
          <Tooltip label={"Number of installed devices on this property"}>
            <HStack>
              <Text fontSize={12}>DEVICES</Text>
              <Icon as={BiHelpCircle} />
            </HStack>
          </Tooltip>
        ),
      enableGlobalFilter: false,
      cell: ({ row }) => {
        const { totalDeviceCount } = row.original;

        if (totalDeviceCount === 0) {
          return null;
        }

        return <DeviceCounts deviceCounts={row.original.deviceCounts} size="sm" />;
      },
    }),
    columnHelper.accessor("tetherTickCompleted", {
      enableHiding: hideDeviceCounts,
      header: () =>
        hideDeviceCounts ? null : (
          <Tooltip label={"Indicates whether a Tether Tick inspection been completed on this property"}>
            <HStack justifyContent="center">
              <Text fontSize={12}>Tether Tick</Text>
              <Icon as={BiHelpCircle} />
            </HStack>
          </Tooltip>
        ),
      enableGlobalFilter: false,
      cell: ({ row }) => {
        const { tetherTickCompleted } = row.original;

        if (!tetherTickCompleted) return null;

        return (
          <Box textAlign={"center"}>
            <Image src={"/assets/tethertickSVG.svg"} height={19} width={17} alt="Tether Tick" />
          </Box>
        );
      },
    }),
    columnHelper.accessor("palaceId", {
      header: () => "",
      enableGlobalFilter: false,
      cell: ({ row }) => {
        return (
          <PropertyListMenu
            propertyId={row.original.id}
            openPalaceModalPropertyId={openPalaceModalPropertyId}
            propertyPalaceId={row.original.palaceId}
          />
        );
      },
    }),

    /** hidden columns for additional search fields */
    columnHelper.accessor("address2", { enableHiding: true }),
    columnHelper.accessor("addressCity", { enableHiding: true }),
    columnHelper.accessor("addressPostcode", { enableHiding: true }),
    columnHelper.accessor("propertyTags", {
      enableHiding: true,
      enableGlobalFilter: true,
    }),
  ];

  return columns;
}
